export const trendline_config = {
    chart: {
        zoomType: 'xy',
        type: 'line',
        height: '300'
    },
    title: {
        text: 'Incident Count - This Year vs. Last Year',
        style: {
            fontWeight: '500',
            color: '#050F2D'
        }
    },
    plotOptions: {
        line: {
            marker: {
                symbol: 'circle',
                radius: 4
            }
        },
        series: {
            cursor: 'pointer',
            stacking: '',
            minPointLength: 0
        }
    },
    yAxis: {
        max: undefined,
        min: undefined,
        endOnTick: false,
        title: false,
        tickAmount: 4
    },
    xAxis: {
        labels: {
            useHTML: true,
            style: {
                fontSize: '10px',
                whiteSpace: 'nowrap'
            },
            formatter() {
                return (this.value).substring(0, 3)
            }
        },
        gridLineWidth: 0,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        minorTickLength: 0,
        tickLength: 0
    },
    credits: {
        enabled: false
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    legend: {
        align: 'center',
        labelFormatter() {
            return `<div style='font-weight: 500'>${this.name}</div>`
        }
    },
    tooltip: {
        useHTML: true,
        headerFormat: '',
        pointFormatter() {
            return `<div class='mb-2'><b>${this.category} ${this.year}</b></div><div>Total Incidents: <b>${this.y.toLocaleString()}</b><div>`
        }
    }
}
