<template>
    <div>
        <div v-if="!ready">
            <fe-spinner class="spinner"/>
        </div>
        <highcharts v-else 
            :options="charts[0].config" 
            class="fe-chart" 
            ref="launchpad-incident-range-chart"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { incidentRangePatterns } from "@/components/charts/chartFormats/patterns/seriesPatterns"
export default {
    name: 'IncidentsRangeChart',
    data() {
        return {
            raw: null,
            charts: [],
            detailedColors: ['#19ADAB', '#FFEEAA', '#FFBD00', '#FF675D'],
            ready: false,
            totalStudents: 0,
        }
    },
    computed: {
        ...mapState('global', ['currentYear', 'defaultSchool']),
    },
    mounted() {
        this.loadData()
    },
    methods: {
        chartConfig() {
            let me = this
            return {
                chart: {
                    type: 'column',
                    stacked: true,
                    height: 300,
                    marginLeft: 0,
                },
                title: {
                    text: "Incident Ranges - This School Year"
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                xAxis: {
                    categories: [],
                    title: {
                        text: null
                    },
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    lineColor: 'transparent',
                    minorTickLength: 0,
                    tickLength: 0
                },
                yAxis: {
                    endOnTick: false,
                    labels: {
                        enabled: false,
                    },
                    title: false,
                    gridLineWidth: 0
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        stacking: 'normal',
                        point: {
                            events: {
                                click(e) {
                                    //me.handleClick(e)
                                }
                            }
                        }
                    }
                },
                legend: {
                    layout: 'vertical',
                    align: 'right',
                    verticalAlign: 'middle',
                    labelFormatter() {
                        let incident = this.name === '1' ? 'Incident' : 'Incidents'
                        let percent = this.yData[0] / me.totalStudents * 100
                        return `<div><span style="font-weight: 600">` +
                            `${this.name} ${incident}</span><br/>` + 
                            `<span style="color:#495B8F;font-size:12px;font-weight:200">(${parseFloat(percent).toFixed(2)}% of students)</span><div>`
                    },
                    itemMarginTop: 20,
                    margin: 0,
                    symbolRadius: 0
                },
                tooltip: {
                    useHTML: true,
                    pointFormatter() {
                        let circle = this.series.legendSymbol.element.outerHTML.replace('<rect', '<circle cx="5" cy="5" r="5"').replace('</rect', '</circle')
                        let legendSymbol = "<svg height='10' width='10'>" + circle + "</svg>"
                        let incident = this.name === '1' ? 'Incident' : 'Incidents'
                        let percent = this.y / me.totalStudents * 100
                        return `<div class="incident-range-chart-series-tooltip">Students with ${this.series.name} ${incident}</div>` +
                         legendSymbol + ` <span class="incident-range-chart-series-tooltip">${parseFloat(percent).toFixed(2)}%</span> (${this.y} of ${me.totalStudents} students)`
                    },
                    headerFormat: ''
                },
                credits: {
                    enabled: false
                },
                series: []
            }
        },
        loadData() {
            const primaryOnly = window.localStorage.getItem('ec-incidents-primary-only')
            const showPrimaryOnly = primaryOnly !== null ? primaryOnly : '1'
            let p = {
                property: 'big5',
                school_year_id: this.currentYear.id,
                include_secondary_behaviors_responses: showPrimaryOnly === '1' ? 0 : 1 ,
                chart_types: 'detailed_pyramid'
            }

            this.$modelGet('studentIncidentChart', p)
                .then(response => {
                    this.raw = response
                    this.buildCharts()
                })
        },
        buildCharts() {
            if (!this.raw) return
            this.charts = []
            this.raw.charts.forEach(cfg => {
                let chartConfig = this.chartConfig()
                //build the series
                chartConfig.series = cfg.score_fields.map((range,i)=> {
                    return { name: range, data: [], color: incidentRangePatterns[i] }
                })

                // build the categories
                cfg.data.forEach((data,i)=>{
                    if (chartConfig.xAxis.categories.indexOf(data[cfg.key] == -1)) chartConfig.xAxis.categories.push(data[cfg.key])

                    chartConfig.series.forEach(serie => { 
                        serie.data[i] = parseInt(data[serie.name]) ? parseInt(data[serie.name]) : 0 
                        this.totalStudents = this.totalStudents + serie.data[i]
                    })
                })

                this.charts.push({
                    config: chartConfig
                })
            })
            this.ready = true
        }
    }
}
</script>

<style lang="scss" scoped>
.fe-chart {
    ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
    ::v-deep .highcharts-title {
        width: 100% !important;
        left: 0 !important;
        padding: 5px 0 37px 16px !important;
        font-size: 14px !important;
        color: #050F2D !important;
    }
}
.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
    z-index: 1000;
}
</style>

<style lang="scss">
.incident-range-chart-series-tooltip {
    font-weight: 600;
}
</style>
