export const incident_trendline_data = [
    {
        occurrence_month: 'July',
        school_year_name: '2023-2024',
        total_incidents: 55
    },
    {
        occurrence_month: 'August',
        school_year_name: '2023-2024',
        total_incidents: 59
    },
    {
        occurrence_month: 'September',
        school_year_name: '2023-2024',
        total_incidents: 75
    },
    {
        occurrence_month: 'October',
        school_year_name: '2023-2024',
        total_incidents: 120
    },
    {
        occurrence_month: 'November',
        school_year_name: '2023-2024',
        total_incidents: 90
    },
    {
        occurrence_month: 'December',
        school_year_name: '2023-2024',
        total_incidents: 100
    },
    {
        occurrence_month: 'January',
        school_year_name: '2023-2024',
        total_incidents: 80
    },
    {
        occurrence_month: 'February',
        school_year_name: '2023-2024',
        total_incidents: 110
    },
    {
        occurrence_month: 'March',
        school_year_name: '2023-2024',
        total_incidents: 80
    },
    {
        occurrence_month: 'April',
        school_year_name: '2023-2024',
        total_incidents: 100
    },
    {
        occurrence_month: 'May',
        school_year_name: '2023-2024',
        total_incidents: 50
    },
    {
        occurrence_month: 'June',
        school_year_name: '2023-2024',
        total_incidents: 30
    },
    {
        occurrence_month: 'July',
        school_year_name: '2024-2025',
        total_incidents: 30
    },
    {
        occurrence_month: 'August',
        school_year_name: '2024-2025',
        total_incidents: 50
    },
    {
        occurrence_month: 'September',
        school_year_name: '2024-2025',
        total_incidents: 55
    },
    {
        occurrence_month: 'October',
        school_year_name: '2024-2025',
        total_incidents: 75
    },
    {
        occurrence_month: 'November',
        school_year_name: '2024-2025',
        total_incidents: 111
    },
    {
        occurrence_month: 'December',
        school_year_name: '2024-2025',
        total_incidents: 120
    },
    {
        occurrence_month: 'January',
        school_year_name: '2024-2025',
        total_incidents: 130
    },
]

export const march = [
    {
        date: '2025-03-03',
        count: 0
    },
    {
        date: '2025-03-04',
        count: 10
    },
    {
        date: '2025-03-05',
        count: 4
    },
    {
        date: '2025-03-06',
        count: 0
    },
    {
        date: '2025-03-07',
        count: 3
    },
    {
        date: '2025-03-10',
        count: 4
    },
    {
        date: '2025-03-11',
        count: 1
    },
    {
        date: '2025-03-12',
        count: 1
    },
    {
        date: '2025-03-13',
        count: 4
    },
    {
        date: '2025-03-14',
        count: 8
    },
    {
        date: '2025-03-17',
        count: 0
    },
    {
        date: '2025-03-18',
        count: 0
    },
    {
        date: '2025-03-19',
        count: 2
    },
    {
        date: '2025-03-20',
        count: 4
    },
    {
        date: '2025-03-21',
        count: 2
    },
    {
        date: '2025-03-24',
        count: 0
    },
    {
        date: '2025-03-25',
        count: 7
    },
    {
        date: '2025-03-26',
        count: 8
    },
    {
        date: '2025-03-27',
        count: 8
    },
    {
        date: '2025-03-28',
        count: 8
    },
    {
        date: '2025-03-31',
        count: 1
    }
]