<template>
    <div>
        <chart
            v-if="incidentChartData"
            ref="launchpad-incident-chart"
            :config="incidentChartData"
            :menu="false"
            @chartClicked="incidentClicked"
            data-test="launchpad-incidents-chart"
        />
        <div v-else>
            <div v-if="newIncidentCard">
                <div v-if="!ready">
                    <fe-spinner class="spinner"/>
                </div>
                <div v-else style="align-content: center; height: 280px; text-align: center; color: #495B8F;">
                    <v-icon style="font-size: 32px; color: #495B8F;" class="mb-5 incidents-unavailable-icon">fal fa-exclamation-circle</v-icon>
                    <div style="font-weight: 700">Data temporarily unavailable</div>
                    <div>Refresh the page or try again later.</div>
                </div>
            </div>
            <div v-else>
                <div
                    style="cursor: pointer; vertical-align: middle; height: 280px;"
                    @click="$router.replace('/Incidents')"
                    data-test="launchpad-incidents-search"
                >
                    <fe-empty-state icon="fa-search" text="No data found.  Click to search"/>
                </div>

                <div class="text-center">{{ startDate }} to {{ endDate }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import windowOptionsMixin from '@/mixins/windowOptions'
import Chart from "@/components/charts/Chart"

export default {
    name: 'IncidentsBarChart',

    components: {
        Chart,
    },

    mixins: [windowOptionsMixin],

    data() {
        return {
            tools: [],
            items: [],
            incidentChartData: false,
            endDate: undefined,
            incidentTypes: [],
            ready: false,
        }
    },

    computed: {
        ...mapState('global', ['defaultSchool']),

        startDate() {
            if (!this.endDate) return undefined
            return this.$dayjs(this.endDate).subtract(14, 'd').format('YYYY-MM-DD')
        },

        newIncidentCard() {
            return this.$store.getters['flags/flags']['ec-behavior-viz']
        },
    },

    watch: {
        endDate(n, o) {
            if (o && n && n !== o) {
                this.loadChartData()
            }
        }
    },

    mounted() {
        this.endDate = this.$dayjs().format('YYYY-MM-DD')
        if(this.newIncidentCard) {
            this.loadIncidentTypes()
        } else {
            this.loadIncidents()
        }
    },

    methods: {
        loadIncidents() {
            this.ready = false
            let url = this.$models.getUrl('studentIncidentChart', 'read')
            let p = {
                chart_types: ['incident_behavior'],
                start_date: this.startDate,
                end_date: this.endDate,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'incidents')
                    data.charts.forEach(chart => {
                        let me = this
                        let categories = chart.fields ? chart.fields : []

                        if (categories.length > 0) {
                            let [series, cats] = this.buildSeries(categories, chart)

                            this.incidentChartData = {
                                chart: {
                                    type: 'bar',
                                    height: me.newIncidentCard ? 300 : 250,
                                },
                                title: false,
                                subtitle: false,
                                xAxis: {
                                    categories: cats,
                                    labels: {
                                        enabled: false
                                    },
                                    title: {
                                        text: null
                                    },
                                },
                                yAxis: {
                                    endOnTick: false,
                                    title: me.newIncidentCard ? false : {
                                        text: 'Total Count',
                                        align: 'high'
                                    },
                                    labels: {
                                        overflow: 'justify',
                                        enabled: me.newIncidentCard ? false : true
                                    },
                                },
                                plotOptions: {
                                    bar: {
                                        dataLabels: {
                                            enabled: true
                                        },
                                        color: me.newIncidentCard ? '#2B87FF' : null
                                    }
                                },
                                legend: false,
                                credits: {
                                    enabled: false
                                },
                                series: series
                            }
                            if(this.newIncidentCard) {
                                this.incidentChartData.xAxis = {
                                    categories: cats,
                                    labels: {
                                        enabled: true,
                                        style: {
                                            color: '#050F2D'
                                        }
                                    },
                                    title: {
                                        text: null
                                    },
                                    gridLineWidth: 0,
                                    lineWidth: 0,
                                    minorGridLineWidth: 0,
                                    lineColor: 'transparent',
                                    minorTickLength: 0,
                                    tickLength: 0
                                }
                                this.incidentChartData.title = {
                                    text: 'Top Incidents in the Last 2 Weeks',
                                    style: {
                                        fontWeight: '500',
                                        color: '#050F2D'
                                    }
                                }
                                this.incidentChartData.yAxis.gridLineWidth = 0
                                this.incidentChartData.tooltip = {
                                    formatter() {
                                        // want to add incident type back to incident code but at the end
                                        let point = this.series.data.find(x => x.index === this.point.index)
                                        let found = me.incidentTypes.find(x => point.incident_behavior_name.includes(x.name))
                                        let name = point.incident_behavior_name
                                        if(found) {
                                            name = `${point.category} (${found.name})`
                                        }
                                        let start = me.$dayjs(me.startDate).format('MM/DD/YYYY')
                                        let end = me.$dayjs(me.endDate).format('MM/DD/YYYY')
                                        return `<b>${name}</b> <br/> ${start} - ${end}: <b>${this.y}</b> incident${this.y > 1 || this.y < 1 ? 's' : ''}`
                                    }
                                }
                            }
                        }
                    })
                    this.ready = true
                })
        },

        incidentClicked(event, point) {
            let incidentId = point.incident_behavior_id
            let incidentName = point.incident_behavior_name.match(/.+-\s(.+)/)[1]

            let chartParams = {
                start_date: this.startDate,
                end_date: this.endDate,
                school_year_id: [{id: point.school_year_id, name: point.school_year_name}],
                incident_behavior_id: [{id: incidentId, name: incidentName}],
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id

            let chartFilters = [{
                key: 'incident_behavior_id',
                name: incidentName,
                id: incidentId
            }]

            if(this.newIncidentCard) {
                let params = {chartParams, chartFilters}
                this.$router.replace({ name: 'Incident Charting', params })
            } else {
                this.openIncidentWindow(chartParams, chartFilters)
            }
        },

        openIncidentWindow(chartParams, chartFilters) {
            this.$store.commit('global/addDockableWindow', {
                name: 'Incident Charting',
                component: 'incident-charting',
                attrs: {
                    chartParams,
                    chartFilters,
                    windowed: true,
                }
            })
        },

        buildSeries(categories, cfg) {
            let series = []

            cfg.data.forEach(rec => {
                let exists = series.find(serie => {
                    return serie.name === rec.school_year_name
                })

                if (!exists) {
                    series.push({
                        name: rec.school_year_name,
                        schoolYearId: rec.school_year_id,
                        data: []
                    })
                }
            })

            series.forEach(serie => {
                categories.forEach(cat => {
                    let rec = cfg.data.find(data => data[cfg.x_field] === cat)
                    let data = rec ? rec : {}
                    data.y = rec ? rec.total_count : 0
                    serie.data.push(data)
                })
            })


            if(this.newIncidentCard) {
                series[0].data = series[0].data.sort((a, b) => b.total_count - a.total_count)
                series[0].data = series[0].data.slice(0,10)
                // we need categories (left hand side / bar chart x-axis) to match new order
                categories = categories.sort((a, b) => {
                    const indexA = series[0].data.findIndex(item => item['incident_behavior_name'] === a)
                    const indexB = series[0].data.findIndex(item => item['incident_behavior_name'] === b)
                    if (indexA === -1) return 1
                    if (indexB === -1) return -1
                    return indexA - indexB
                })
                // want to remove incident type from category so we just have incident code
                categories = categories.map(cat => {
                    let found = this.incidentTypes.find(x => cat.includes(x.name))
                    if(found) {
                        let type = found.name
                        cat = cat.replace(type + ' - ', '')
                    }
                    return cat
                })
            }

            return [series, categories]
        },

        loadIncidentTypes() {
            this.$axios.get(this.$models.getUrl('incidentBehaviorType', 'read') + '&active=1')
                .then(response => {
                    this.incidentTypes = response.data.incident_behavior_type
                    this.loadIncidents()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
}
</style>
