<template>
    <explore-widget
        widgetTitle="Incidents"
        :toolTip="dynamicToolTip"
        dataTestBaseString="incident"
        :cardHeight="launchpadWidgetHeights.secondRow"
        :countCard="countCard"
        barChartBtn 
        countBtn
        heatmapBtn
        rangeBtn
        trendBtn
        @exploreClick="exploreIncidentClicked"
        @printChart="printChart"
        @exportChart="exportChart"
        @barChartClick="activeTab = 1"
        @countClick="activeTab = 2"
        @heatmapClick="activeTab = 3"
        @trendClick="activeTab = 4"
        @rangeClick="activeTab = 5"
        ref="incidents-explore-widget"
    >
        <template v-slot:widget-body>
            <div v-if="activeTab === 1 || !newIncidentCard">
                <incidents-bar-chart ref="incidents-bar-chart"></incidents-bar-chart>
            </div>
            <div v-else-if="activeTab === 2">
                <div v-if="!countCard.show">
                    <div style="align-content: center; height: 280px; text-align: center; color: #495B8F;">
                        <v-icon style="font-size: 32px" class="mb-5">fal fa-exclamation-circle</v-icon>
                        <div style="font-weight: 700">Data temporarily unavailable</div>
                        <div>Refresh the page or try again later.</div>
                    </div>
                </div>
            </div>
            <div v-else-if="activeTab === 3">
                <calendar-heat-map :params="march" height="300" ref="incidents-heat-map"></calendar-heat-map>
            </div>
            <div v-else-if="activeTab === 4">
                <incidents-trendline ref="incidents-trendline"></incidents-trendline>
            </div>
            <div v-else-if="activeTab === 5">
                <incidents-range-chart ref="incidents-range-chart"></incidents-range-chart>
            </div>
            <div v-else>
                <div style="align-content: center; height: 280px; text-align: center; color: #495B8F;">
                    <v-icon style="font-size: 32px; color: #495B8F;" class="mb-5 incidents-unavailable-icon">fal fa-exclamation-circle</v-icon>
                    <div style="font-weight: 700">Data temporarily unavailable</div>
                    <div>Refresh the page or try again later.</div>
                </div>
            </div>
        </template>
    </explore-widget>
</template>

<script>
import { mapState } from 'vuex'
import ExploreWidget from '../ExploreWidget.vue'
import IncidentsBarChart from './IncidentsBarChart.vue'
import IncidentsRangeChart from './IncidentsRangeChart.vue'
import CalendarHeatMap from '@/components/charts/CalendarHeatMap.vue'
import IncidentsTrendline from './IncidentsTrendline.vue'
import { march } from './incident_test_data'

export default {
    name: 'IncidentsCard',

    components: {
        ExploreWidget,
        IncidentsBarChart,
        IncidentsRangeChart,
        CalendarHeatMap,
        IncidentsTrendline,
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool', 'lastLaunchpadVisit', 'launchpadWidgetHeights']),

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        },

        dynamicToolTip() {
            if (this.hasRestrictedIncidentTypes) {
                return 'There are some incident types that have been restricted from your visibility by your district'
            } else if (this.newIncidentCard) {
                return 'These performance bands provide a breakdown of students with different ranges of incidents. Ranges can be customized in System Configuration.'
            }
            return ''
        },

        newIncidentCard() {
            return this.$store.getters['flags/flags']['ec-behavior-viz']
        },
    },

    data() {
        return {
            tab: [
                { id: 1, name: 'bar' },
                { id: 2, name: 'count' },
                { id: 3, name: 'heatmap' },
                { id: 4, name: 'range' }
            ],
            activeTab: 1,
            countCard: {
                text: 'Total incidents this school year',
                show: false,
                change: null,
                count: null,
                displayUpDownArrow: null,
                title: 'incidents'
            },
            march: march,
        }
    },

    watch: {
        activeTab(v) {
            if(v === 2) {
                this.loadCountData()
            } else {
                this.countCard.show = false
            }
        }
    },

    methods: {
        exploreIncidentClicked() {
            let chartParams = {
                school_year_id: [{id: this.currentYear.id, name: this.currentYear.name}],
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id

            chartParams.end_date = this.$dayjs().format('YYYY-MM-DD')
            chartParams.start_date = this.$dayjs(chartParams.end_date).subtract(14, 'd').format('YYYY-MM-DD')

            let chartFilters = []
            let location = 'launchpad'
            let params = {chartParams, chartFilters, location}

            this.$router.replace({ name: 'Incident Charting', params })
        },

        printChart() {
            let incidentChart = this.$refs['incidents-bar-chart']?.$refs['launchpad-incident-chart']
            let incidentRangeChart = this.$refs['incidents-range-chart']?.$refs['launchpad-incident-range-chart'].chart
            let incidentHeatMap = this.$refs['incidents-heat-map']?.$refs['calendar-heat-map'].chart
            let incidentTrendline = this.$refs['incidents-trendline']?.$refs['launchpad-incident-trendline']
            if(incidentChart) incidentChart.printChart()
            else if(incidentRangeChart) incidentRangeChart.print()
            else if(incidentHeatMap) incidentHeatMap.print()
            else if(incidentTrendline) incidentTrendline.printChart()
        },

        exportChart() {
            let incidentChart = this.$refs['incidents-bar-chart']?.$refs['launchpad-incident-chart']
            let incidentRangeChart = this.$refs['incidents-range-chart']?.$refs['launchpad-incident-range-chart'].chart
            let incidentHeatMap = this.$refs['incidents-heat-map']?.$refs['calendar-heat-map'].chart
            let incidentTrendline = this.$refs['incidents-trendline']?.$refs['launchpad-incident-trendline']
            if(incidentChart) {
                incidentChart.exportChart({
                    type: 'image/png',
                    filename: 'Incident Summary Last Two Weeks'
                })
            } else if(incidentRangeChart) {
                incidentRangeChart.exportChart({
                    type: 'image/png',
                    filename: 'Incident Range'
                })
            } else if(incidentHeatMap) {
                incidentHeatMap.exportChart({
                    type: 'image/png',
                    filename: 'Incident Calendar'
                })
            } else if(incidentTrendline) {
                incidentTrendline.exportChart({
                    type: 'image/png',
                    filename: 'Incident Summary Comparison'
                })
            }
        },

        loadCountData() {
            this.countCard = {
                text: 'Total incidents this school year',
                show: true,
                change: 13,
                count: 458,
                displayUpDownArrow: 'down',
                title: 'incidents'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
